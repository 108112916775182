import React, { Component } from "react";
import { Helmet } from "react-helmet"
const siteConfig=require('../../../config')

export default class ProducData extends Component {
  structuredJSON = () => {
    const { name, slug, price, refBrand, refModel, images } = this.props;

    let data = {
      "@context": "http://schema.org/",
      "@type": "Product",
      "name": `${name}`,
      "brand": refBrand ? `${refBrand.name}` : '',
      "sku": `${name}`,
      "description": ( refModel !== null ) ? `${name}, ${refModel.name}` : `${name}`,
      "url": `${siteConfig.domains[ process.env.GATSBY_LANG ]}${slug}`,
      "offers": {
          "@type": "Offer",
          "priceCurrency": "€",
          "price": `${parseFloat( price )}`,
          "availability": "InStoreOnly",
          "itemCondition": "https://schema.org/UsedCondition",
          "seller": {
              "@type": "Organization",
              "name": `${siteConfig.siteNames[ process.env.GATSBY_LANG ]}`
          },
          "url": `${siteConfig.domains[ process.env.GATSBY_LANG ]}${slug}`,
          "priceValidUntil": "2022/01/01"
      },
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.3",
        "reviewCount": "245"
      },
      "review": {
        "@type": "Review",
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "4.3",
          "bestRating": "5"
        },
        "author": {
          "@type": "Website",
          "name": `${siteConfig.siteNames[ process.env.GATSBY_LANG ]}`
        }
      },
    }
    // brand
    if(refBrand) {
      data['brand'] = {
          "@type": "Thing",
          "name": `${refBrand['name']}`
      };
    } 
    // Images
    if(images){
      data['image'] = images.url.map(image => siteConfig.domains[ process.env.GATSBY_LANG ] + 'photos' + image);
    }

    return JSON.stringify(data);
  }

  render() {    

    return (
      <Helmet>
        <script className='structured-data-product-2' type="application/ld+json">{this.structuredJSON()}</script>
      </Helmet>
    );
  }
}