import React, { useContext, useState } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import {
    Box,
    Button,

} from '@chakra-ui/react'
import { UserContext } from '../userContext';
import { AlertBullet } from '../UI/AlertBullet'

import config from '../../../config'

const AlertMessageButton = () => {

    const userContext = useContext(UserContext);
    const user = userContext ? userContext.user : null;

    return (
        <Button
            as={GatsbyLink}
            // display='block'
            to={config.links.account[process.env.GATSBY_LANG] + 'discussions'}
            // padding="8px 10px"
            // border='solid 1px'
            // borderColor='blue.brand'
            // borderRadius='3px'
            // color='blue.brand'
            boxShadow='md'
            className={user && user.unreadMessages ? 'shake' : ''}
            fontSize={{ base: 'sm', lg: 'md' }}
            position='relative'
            bgColor='white'
            _hover={{
                bg: 'blue.brand',
                color: 'white'
            }}
        >
            <AlertBullet count={user ? user.unreadMessages : ''} />
            Messages
        </Button>
    )
}

export default AlertMessageButton