import React, { useContext } from 'react'

import {
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/react"
import AdLoader from './AdLoader'
import { AdContext } from './adContext';

export const AdModal = ({
    adId,
}) => {
    const adContext = useContext(AdContext);

    const setAd = adContext ? adContext.setAd : null

    const handleClose = () => (
        setAd(null)
    )

    return (


        <Modal
            isOpen={adId ? true : false}
            size='full'
            onClose={
                () => {
                    handleClose(null)
                }
            }
        >
            <ModalContent
                bg='none'
            >
                <ModalBody
                    p={0}
                    minH='100vh'
                    maxH='100vh'
                    overflowY={'scroll'}
                    zIndex={'modal'}
                    bg='none'
                >
                    <Box
                    // bgColor='green'
                    // onClick={
                    //     (e) => { console.log(e.target) }

                    // }
                    >
                        <AdLoader
                            adId={adId}
                            handleUnload={
                                () => { handleClose() }
                            }
                        />
                    </Box>

                </ModalBody>
                <ModalOverlay zIndex={'base'} />
            </ModalContent>
        </Modal>
    )
}
