import * as React from 'react'
import {
    Box
} from '@chakra-ui/react'

interface alertBulletProp {
    count: number
}

export const AlertBullet:React.FC < alertBulletProp> = ({ count }) => (

    count ? 
    <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        position='absolute'
        right='-5px'
        top='-8px'
        color='white'
        fontSize='12px'
        bg='red.500'
        borderRadius='10px'
        fontWeight='bold'
        w='auto'
        h='16px'
        px='.2rem'
        minW='16px'
    >
        {count}
    </Box>
    : null
)