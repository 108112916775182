import React, { Component, useState, useContext } from 'react';
import { Link as GatsbyLink } from 'gatsby';

import {
    Box,
    Button,
    Link,
    Flex,
    Portal,
    Stack
} from "@chakra-ui/react"
import CurrencySwitcherTabs from '../CurrencySwitcherTabs'
import { FormattedMessage } from 'react-intl'
import logo from '../../static/svg/logo.svg'

import { BiArrowBack, BiChevronDown, BiDownArrown, BiAnchor } from 'react-icons/bi';
import { UserContext } from '../userContext';
import Debug from '../../utils/Debug';
import { SubNav } from './SubNav';
import { AlertBullet } from '../UI/AlertBullet';


const Nav = () => {

    const [isSubNavActive, setSubNavIsActive] = useState(false);
    const toggleNav = () => {
        setSubNavIsActive(!isSubNavActive);
    }

    const userContext = React.useContext(UserContext)
    const user = userContext ? userContext.user : null;

    const navButton = React.useRef();

    return (
        <>
            <Flex
                alignItems={'center'}
                position={'relative'}
                zIndex={{ base: 'tooltip', lg: 'base' }}
            >
                <Link
                    as={GatsbyLink}
                    to="/"
                    display={{ base: "none", lg: "flex" }}
                    mr={{ base: 0, md: 8 }}
                    borderRadius={{ base: 1, lg: 4 }}
                    bg={{ base: 'blue.brand', lg: 'initial' }}
                    fontWeight={700}
                    boxShadow={{ base: "none" }}
                    border={{ base: 'none' }}
                    letterSpacing={{ base: 1, lg: 1.25 }}
                    _hover={{
                        borderColor: "blue.brand",
                    }}
                >
                    <Box
                        display={{ base: 'none', lg: 'flex' }}
                        fontSize={{ md: 24 }}
                        color="gray.600"
                        pl='3rem'
                        alignItems='center'
                        background={`url(${logo}) no-repeat center left`}
                    >
                        <FormattedMessage id="main.sitename" />
                    </Box>
                </Link>
                <Stack
                    position={{ base: 'absolute', lg: 'initial' }}
                    zIndex={{ base: 'banner', lg: 'base' }}

                    top='0'
                    right='0'
                    w={{ base: '50vw', lg: 'auto' }}
                    justifyContent='flex-end'
                    p={{ base: '0', lg: '0' }}
                    spacing={{ base: '.5rem' }}
                    isInline
                    shouldWrapChildren={true}
                    alignItems={'center'}
                    spacing={5}

                >
                    <Box
                        ref={navButton}

                        as="button"
                        onClick={toggleNav}
                        position={{ base: "fixed", lg: "relative" }}
                        bottom={{ base: "1rem", lg: 0 }}
                        right={{ base: "1rem", lg: 0 }}
                        backgroundColor="blue.brand"
                        color="white"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-around"
                        padding="8px 10px"
                        borderRadius="4px"
                        role="button"
                        fontWeight={'500'}
                        zIndex={{ base: 'banner', lg: 'base' }}
                        hover={{
                            backgroundColor: "blue.700"
                        }}
                        whiteSpace={'pre'}
                    >
                        <Box mr={1}>
                            <BiAnchor />
                        </Box>
                        <FormattedMessage id="nav.nav" />
                        <AlertBullet count={user ? user.unreadMessages : 0} />
                    </Box>
                    {process.env.GATSBY_LANG === 'fr' &&
                        userContext && Object.keys(user).length
                        ?
                        <Button
                            display={{ base: 'none', lg: 'flex' }}
                            variant='link'
                            as={GatsbyLink}
                            to='/capitainerie/don'
                            color='gray.700'
                        >
                            <FormattedMessage id='donation.button.label' /> 🤙
                        </Button>
                        : null}
                    <Portal>
                        <SubNav
                            isSubNavActive={isSubNavActive}
                            toggleNav={toggleNav}
                            isLogged={userContext && Object.keys(user).length ? true : false}
                            buttonPosition={navButton && navButton.current ? navButton.current.getBoundingClientRect() : null}
                        />
                    </Portal>
                </Stack>
            </Flex>


        </>
    )
}

export default Nav;