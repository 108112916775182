import React, { useContext, useState } from 'react'
import { Box, Button } from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import { UserContext } from '../userContext';
import { AlertBullet } from '../UI/AlertBullet'
import { Link as GatsbyLink } from 'gatsby'

const AlertMessageButton = (props) => {
    const [panelOpen, setPanelOpen] = useState();
    const userContext = useContext(UserContext);
    const user = userContext ? userContext.user : null;

    if (
        !user
        || !user.favorites
        || user.favorites.length === 0
    ) {
        return null
    }

    else {
        return (
            <>
                <Button
                    position='relative'
                    bg='white'
                    boxShadow='md'
                    fontSize={{ base: 'sm', lg: 'md' }}

                    as={GatsbyLink}
                    to='/favoris'

                    _hover={{
                        bg: 'blue.brand',
                        color: 'white'
                    }}
                    onClick={() => {
                        setPanelOpen(true)
                    }}
                >
                    <AlertBullet count={4} />
                    <FormattedMessage id="main.favorites" />
                </Button>
            </>
        )
    }
}

export default AlertMessageButton