import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Button, ChakraProvider, CSSReset, Box, Flex, Link, useColorModeValue, useColorMode, Wrap } from '@chakra-ui/react'
// import theme from "../../theme/wanaboat.js";
import theme2 from "../../theme/";
// import messages from '../locales/'
import Nav from '../nav'
import OrganisationStructuredData from '../StructuredData/organisation'
import '../../assets/fonts/stylesheet.css'
import theme from "../../theme";
import Wrapper from "../Wrapper";
import { AdContext } from "../ad/adContext";
import { AdModal } from "../ad/modal";



import messagesFr from '../locales/fr'
import messagesEn from '../locales/en'
import { IntlProvider } from "react-intl";
import { Helmet } from "react-helmet";

let lang = process.env.GATSBY_LANG

const messages = { ...messagesFr, ...messagesEn }

let i18nConfig = {
    locale: lang,
    messages: messages
};


const formats = {
    number: {
        EUR: {
            style: 'currency',
            currency: 'EUR'
        },
        GBP: {
            style: 'currency',
            currency: 'GBP'
        },
        USD: {
            style: 'currency',
            currency: 'USD'
        }
    }
};



const LayoutPublic = ({ children }) => {
    const { colorMode, toggleColorMode } = useColorMode()

    const adContext = useContext(AdContext);
    const ad = adContext && adContext.ad ? adContext.ad : null;

    return (
        <IntlProvider
            locale={process.env.GATSBY_LANG}
            formats={formats}
            defaultFormats={formats}
            defaultLocale={i18nConfig.locale}
            messages={i18nConfig.messages[lang]}
        >
            <AdModal adId={ad} />
            {/* <ChakraProvider
                theme={theme}
            > */}
            {/* <AlernativeLanguageBanner lang={lang} /> */}
            {/* <CSSReset /> */}
            <Flex as="main"
                borderStyle="solid"
                borderLeftWidth={{ base: '0', lg: '10px' }}
                borderLeftColor="blue.brand"

                borderTopWidth={{ base: '10px', lg: '0' }}
                borderTopColor="blue.brand"


                minHeight="100vh"
                w={{ base: '100vw' }}
                // background="linear-gradient(180deg, #FFFFFF 0%, #E1F5FF 100%), #C4C4C4"
                wrap="wrap"

                pt={{ base: 0, lg: 4 }}

                bgColor={useColorModeValue('gray.100', 'gray.900')}
            >

                {/* <Button onClick={toggleColorMode}>
                    Toggle {colorMode === 'light' ? 'Dark' : 'Light'}
                </Button> */}
                <Wrapper>
                    {children}
                </Wrapper>
                <Box
                    as='footer'
                    backgroundColor='blue.brand'
                    color='white'
                    p='2rem'
                    w='100%'
                    alignSelf='flex-end'
                >
                    © Wanaboat en français : <Link _hover={{ textDecoration: 'underline' }} hrefLang="fr" href="https://www.wanaboat.fr">Wanaboat.fr</Link> / iWannaboat in english : <Link _hover={{ textDecoration: 'underline' }} hrefLang="en" href="https://iwannaboat.com">iWannaboat.com</Link>
                </Box>
            </Flex>
            <OrganisationStructuredData />
            {/* </ChakraProvider> */}
        </IntlProvider >
    )
}

LayoutPublic.propTypes = {
    children: PropTypes.node.isRequired,
}

export default LayoutPublic