import React, { useState, useEffect } from 'react'
import { Stack, Button, } from '@chakra-ui/react'
import axios from 'axios'
const CurrencySwitcherTabs = (props) => {
    const [currentCurrency, setCurrency] = useState('EUR')
    let newCurrency = null
    const switchCurrency = (value) => {
        setCurrency(value)
        getChangeRate(value)
    }

    useEffect(() => {
        let currency = JSON.parse(localStorage.getItem('currency'))
        if (currency) {
            setCurrency(currency.name)
        }
    }, []);

    const getChangeRate = (currency) => {

        if (currency === 'EUR') {
            localStorage.setItem('currency', `{"name":"EUR", "rate":"1"}`)
            document.location.reload(true)
        } else {
            axios.get('https://api.exchangeratesapi.io/latest')
                .then(function (response) {
                    // handle success
                    let rate = response.data.rates[currency]
                    localStorage.setItem('currency', '{"name":"' + currency + '", "rate":"' + rate + '" }')
                    document.location.reload(true)
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
                .then(function () {
                    // always executed
                });
        }


    }
    return false

    return (
        // <Box
        //     ml='1rem'
        //     border="solid 1px"
        //     borderColor="blue.brand"
        //     as="button"
        //     bottom="1rem"
        //     right="1rem"
        //     // display={{ lg: "none" }}
        //     zIndex="sticky"
        //     backgroundColor="transparent"
        //     color="blue.brand"
        //     display="flex"
        //     alignItems="center"
        //     justifyContent="space-around"
        //     padding="8px 10px"
        //     borderRadius="4px"
        //     role="button"
        //     hover={{
        //         backgroundColor: 'blue.brand',
        //         color: 'white'
        //     }}
        //     onClick={ switchCurrency }
        // >
        //     <FormattedMessage id="main.currency" /> : { currentCurrency }
        // </Box>

        <Stack
            isInline
            spacing='0'
            textTransform="uppercase"
            w={props.width === 'full' ? '100%' : 'auto'}
            flexGrow='1'
        >
            {/* <Box
                // w='33%'
                onClick={ () => { switchCurrency('EUR') }}
                boxShadow='xs'
                textTransform='uppercase'
                color='white'
                backgroundColor={ currentCurrency === 'EUR' ? 'blue.brand' : 'white' }
                borderTopRightRadius={0}
                borderBottomRightRadius={0}
                display='flex'
                justifyContent='center'
                alignItems='center'
                fontWeight='bold'
                fontSize='14px'
                borderRadius='3px 0 0 3px'
                px='1rem'
                _hover={

                }
            >
                Eur €
            </Box> */}
            <Button
                w={props.width === 'full' ? '33%' : 'auto'}
                onClick={() => { switchCurrency('EUR') }}
                size="sm"
                boxShadow="xs"
                textTransform="uppercase"
                backgroundColor={currentCurrency === 'EUR' ? 'blue.brand' : 'white'}
                color={currentCurrency === 'EUR' ? 'white' : 'gray.800'}
                borderTopRightRadius={0}
                borderBottomRightRadius={0}
                pointerEvents={currentCurrency === 'EUR' ? 'none' : 'auto'}
            >Eur €</Button>
            <Button
                w={props.width === 'full' ? '33%' : 'auto'}
                onClick={() => { switchCurrency('GBP') }}
                size="sm"
                boxShadow="xs"
                textTransform="uppercase"
                backgroundColor={currentCurrency === 'GBP' ? 'blue.brand' : 'white'}
                color={currentCurrency === 'GBP' ? 'white' : 'gray.800'}
                borderRadius={0}
                pointerEvents={currentCurrency === 'GBP' ? 'none' : 'auto'}
            >GBP £</Button>
            <Button
                w={props.width === 'full' ? '33%' : 'auto'}
                onClick={() => { switchCurrency('USD') }}
                size="sm"
                boxShadow="xs"
                textTransform="uppercase"
                backgroundColor={currentCurrency === 'USD' ? 'blue.brand' : 'white'}
                color={currentCurrency === 'USD' ? 'white' : 'gray.800'}
                borderTopLeftRadius={0}
                borderBottomLeftRadius={0}
                pointerEvents={currentCurrency === 'USD' ? 'none' : 'auto'}
            >Usd $</Button> */}
        </Stack>

    )
}

export default CurrencySwitcherTabs