import React, { Component } from "react";
import { Helmet } from "react-helmet"
const config = require('../../../config')

export default class StructuredOrganisation extends Component {
    structuredJSON = () => {
        let data = {
            "@context": "https://schema.org",
            "@type": "Organization",
            "url": config.domains[ process.env.GATSBY_LANG ],
            "logo": `${config.domains[ process.env.GATSBY_LANG ]}/wanaboat-image-default.png`
        }
        return JSON.stringify(data);
    }
    render() {
        return (
            <Helmet>
                <script className='structured-data-organisation' type="application/ld+json">{this.structuredJSON()}</script>
            </Helmet>
        );
    }
}