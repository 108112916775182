import React, { Component } from 'react';
import { Helmet } from "react-helmet"

import { Link as GatsbyLink } from 'gatsby';
import {
  Center,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,

} from "@chakra-ui/react";
import { FormattedMessage } from 'react-intl';

import { BiChevronRight } from 'react-icons/bi';

export default class BreadcrumbsData extends Component {

  structuredJSON = () => {
    const { items } = this.props;
    let breadcrumbsItems = [];

    var y = 0
    for (let index = 0; index < items.length; index++) {
      const element = items[index];
      if (element === null) { continue; }
      y++
      breadcrumbsItems = breadcrumbsItems.concat(
        {
          "@type": "ListItem",
          "position": y,
          "item":
          {
            "@id": `${process.env.GATSBY_LANG === 'fr' ? 'https://www.wanaboat.fr' : 'https://iwannaboat.com'}${element.slug}`,
            "name": `${element.name}`
          }
        }
      )
    }

    let breadcrumbsList = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": breadcrumbsItems
    }

    return JSON.stringify(breadcrumbsList);
  }

  render() {
    const { items } = this.props;
    return (
      <Breadcrumb
        display={{ base: "none", lg: "initial" }}
        mb={10}
        id="breadcrumbs"
        spacing="8px"
        aria-label="breadcrumbs"
        separator={<Center h='full'><BiChevronRight /></Center>}
      >
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink
            as={GatsbyLink}
            to={'/'}>
            <FormattedMessage id="breadcrumb.used.boats" />
          </BreadcrumbLink>
        </BreadcrumbItem>
        {
          items.map((item, index) =>
            item ?
              <BreadcrumbItem
                key={`breadcrumbitem-${index}`}
              >
                <BreadcrumbLink
                  as={GatsbyLink}
                  to={
                    item.slug.substring(0, 1) === '/' ?
                      item.slug
                      : '/' + item.slug
                  }>{item.name}</BreadcrumbLink>
              </BreadcrumbItem>
              : null
          )
        }
        <Helmet>
          <script id="breadcrumbs-data" type="application/ld+json">{this.structuredJSON()}</script>
        </Helmet>
      </Breadcrumb>
    )
  }
}