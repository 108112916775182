import React, { Component, useState, useContext } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { BiArrowBack } from 'react-icons/bi';
import { FormattedMessage } from 'react-intl'
import AlertMessageButton from './AlertMessageButton'
import FavoritesButton from './FavoritesButton'
import {
    Box,
    Button,
    Link,
    Flex,
    Icon,
    Image,
    Portal,
    Text,
    SimpleGrid,
    Stack
} from "@chakra-ui/react"
import { CSSTransition } from 'react-transition-group';
import Debug from '../../utils/Debug';
const lang = process.env.GATSBY_LANG;
const siteConfig = require("../../../config.js");
const items = {
    "fr": [
        {
            label: "Catamarans",
            subLabel: "Bateaux, voiles, accastillage, remorques",
            to: "/catamarans/"
        },
        {
            label: "Dériveurs",
            subLabel: "Bateaux, voiles, accastillage, remorques",
            to: "/deriveurs/"
        },
        {
            label: "Sportboats & Dayboats",
            subLabel: "Bateaux, voiles, matériel",
            to: "/dayboats-sportboats/"
        },
        {
            label: "Course au large",
            subLabel: "Bateaux, voiles, matériel",
            to: "/course-au-large/"
        },
        {
            label: "Voiliers de croisières",
            subLabel: "Bateaux, accastillage",
            to: "/voiliers-course-croisiere/"
        },
        {
            label: "Équipement du marin",
            subLabel: "Combinaison, gilets, chaussures",
            to: "/equipement-materiel/"
        }
    ],
    "en": [
        {
            label: "Catamarans",
            subLabel: "Boats, sails, parts and trolleys",
            to: "/catamarans/"
        },
        {
            label: "Sailing dinghies",
            subLabel: "Boats, sails, parts & trolleys",
            to: "/sailing-dinghies/"
        },
        {
            label: "Sportboats & Dayboats",
            subLabel: "Boats, parts, sails",
            to: "/dayboats-sportboats/"
        },
        {
            label: "Offshore racing",
            subLabel: "Boats, sails, parts",
            to: "/offshore-racing/"
        }
    ]
}
export const SubNav = ({
    isSubNavActive,
    toggleNav,
    isLogged,
    buttonPosition
}) => (

    // <CSSTransition in={isSubNavActive} timeout={200} classNames="menu">
    // <Portal>
    <React.Fragment>
        {/* <Debug data={buttonPosition} /> */}
        <Box
            left={{ base: 0 }}
            px={{ base: 0 }}
            py={{ base: "1rem", lg: 0 }}
            pb={{ base: "0" }}
            pt={{ base: "0", lg: 0 }}
            w={{ base: '100vw' }}
            h={{ base: '100vh' }}
            position={{ base: 'fixed' }}
            top={0}
            left={0}
            bottom={{ base: 0 }}
            zIndex="tooltip"
            display={isSubNavActive ? 'block' : 'none'}
            maxHeight="100vh"
            zIndex="banner"
            backgroundColor='#FFFFFF44'
            pointerEvents={!isSubNavActive ? 'none' : 'auto'}

            onClick={() => toggleNav()}
        />
        <Box
            display={isSubNavActive ? 'block' : 'none'}
            as="nav"
            position={{ base: 'fixed' }}
            role="navigation"
            aria-label="Main"
            onClick={toggleNav}
            backgroundColor={{ base: 'white', lg: '#F1F1F1' }}
            borderRadius={{ base: 0, lg: "md" }}
            boxShadow={{ base: "0px 2px 15px rgba(0,0,0,0.6)", lg: "0px 2px 8px rgba(0,0,0,0.2)" }}
            p={{ base: '1rem', lg: 4 }}
            left={{ base: 0, lg: buttonPosition ? buttonPosition.x : null }}
            top={{ base: "initial", lg: buttonPosition ? buttonPosition.y + buttonPosition.height + 15 : null }}
            bottom={{ base: "0px", lg: "initial" }}
            // transition="all .2s ease"
            zIndex="tooltip"
            w={{ base: '100vw', lg: "590px" }}
        >
            <Stack
                spacing={3}
            >
                <Button
                    display={{ lg: "none" }}
                    alignSelf="flex-start"
                    leftIcon={<BiArrowBack />}
                    mb="1rem"
                    size='sm'
                >
                    <FormattedMessage id="nav.back" />
                </Button>


                <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={2}>
                    {
                        items[lang] ?
                            items[lang].map((item, index) =>
                                <Box
                                    key={item.to}
                                    as={GatsbyLink}
                                    to={item.to}
                                    display="flex"
                                    flexWrap="wrap"
                                    h="auto"
                                    p="12px 10px"
                                    borderRadius="md"
                                    backgroundColor="white"
                                    boxShadow={{ base: "0px 2px 2px rgba(0, 0, 0, 0.01)", lg: "0px 4px 4px rgba(0, 0, 0, 0.01)" }}
                                    border={{ base: 'none', lg: '1px solid #F1FF1' }}
                                    background="gray.50"
                                    _hover={{
                                        backgroundColor: "blue.50",
                                        borderColor: "blue.100"
                                    }}
                                >
                                    <Text
                                        fontWeight={700}
                                        letterSpacing={{ base: 1, lg: 1.25 }}
                                        textTransform={'uppercase'}
                                        fontSize={{ base: "14px", lg: "14px" }}
                                        lineHeight="18px"
                                        color="blue.brand"
                                        textAlign="left"
                                        as="span"
                                        w="100%">{item.label}</Text>
                                    <Text
                                        as="span" w="100%"
                                        fontWeight="light"
                                        textAlign="left"
                                        fontSize={{ base: "sm" }}
                                        display={{ base: 'none', lg: 'initial' }}
                                    >{item.subLabel}</Text>
                                </Box>
                            )
                            : null
                    }
                </SimpleGrid>
                <SimpleGrid columns={{ base: 2, lg: 2 }} spacing={2}>
                    <Button
                        as={GatsbyLink}
                        boxShadow='md'
                        bgColor='primary'
                        color='white'
                        to={siteConfig.links.publish[process.env.GATSBY_LANG]}
                        fontSize={{ base: 'sm', lg: 'md' }}
                        _hover={{
                            bgColor: 'blue.900'
                        }}
                    >
                        <FormattedMessage id="nav.publish" />
                    </Button>
                    <Button
                        as={GatsbyLink}
                        boxShadow='md'
                        to={siteConfig.links.account[process.env.GATSBY_LANG]}
                        fontSize={{ base: 'sm', lg: 'md' }}
                        bgColor={'white'}
                        _hover={{ bgColor: 'primary', color: 'white' }}
                    >
                        <FormattedMessage id="nav.userAccount" />
                    </Button>

                </SimpleGrid>

                {isLogged ?
                    <SimpleGrid columns={{ base: 2, lg: 3 }} spacing={2}>
                        <Button
                            as={GatsbyLink}
                            boxShadow='md'
                            to={siteConfig.links.account[process.env.GATSBY_LANG]}
                            fontSize={{ base: 'sm', lg: 'md' }}
                            bgColor={'white'}
                            _hover={{ bgColor: 'primary', color: 'white' }}
                        >
                            <FormattedMessage id="main.your.ads" />
                        </Button>

                        <AlertMessageButton />
                        <FavoritesButton />

                    </SimpleGrid>
                    :
                    <Text
                        fontSize='sm'
                        color='gray.600'
                    >
                        <FormattedMessage id="nav.connection.notice" />
                        ,{' '}
                        <Link
                            textDecoration='underline'
                            as={GatsbyLink}
                            to={'/identification'}
                        >
                            <FormattedMessage id="main.login" />
                        </Link>
                        .
                    </Text>
                }


            </Stack>
            <SimpleGrid
                columns={{ base: 1, lg: 2 }}
                mx='-1rem'
                mb='-1rem'
                p='1rem 1rem'
                gap='1rem'
                borderRadius='0 0 .25rem .25rem'
                borderTop="solid .5px"
                borderTopColor='gray.200'
                backgroundColor='gray.100' mt={{ base: 3, lg: 5 }}
                spacing={2}
            >
                {/* <Box>
                                <CurrencySwitcherTabs />
                            </Box> */}
                <Box>
                    <Button
                        variant="link"
                        justifyContent="flex-start"
                        fontWeight='base'
                        color="blue.brand"
                        as={GatsbyLink}
                        to={siteConfig.links.about[process.env.GATSBY_LANG]}
                    >
                        <FormattedMessage id="nav.about.item" />→
                    </Button>
                </Box>
            </SimpleGrid>
        </Box>

    </React.Fragment>
    // </CSSTransition>

)